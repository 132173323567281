<template>
  <div
    class="navbar-container d-flex content align-items-center"
    style="background-color: #283046 !important; border-radius: 5px; color: white"
  >
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <notifications />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content v-if="user">
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-white">
              {{ user ? user.name : "" }}
            </p>
            <span class="user-status text-white">{{
              user ? user.role : ""
            }}</span>
          </div>
          <b-avatar
            v-if="!user.photo"
            size="40"
            variant="light-primary"
            badge
            :text="flew(user ? user.name : '')"
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            size="40px"
            :src="user.url_photo"
            variant="transparent"
            class="badge-minimal"
            badge-variant="success"
            badge
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="showEditProfileModal = true"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Mi Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="showChangePasswordModal = true"
        >
          <feather-icon size="16" icon="LockIcon" class="mr-50" />
          <span>Cambiar Clave</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Right sidebar edit profile -->
    <b-sidebar
      v-model="showEditProfileModal"
      id="sidebar-right"
      title="Editar perfil"
      right
      shadow
    >
      <div class="p-2">
        <edit-photo />

        <edit-profile @executeEditProfile="executeEditProfile" />
      </div>
    </b-sidebar>

    <!-- Right sidebar change password -->
    <b-sidebar
      v-model="showChangePasswordModal"
      id="sidebar-right"
      title="Editar perfil"
      right
      shadow
    >
      <div class="p-2">
        <change-password @executeChangePassword="executeChangePassword" />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BSidebar
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { firstLetterEachWord } from "../../helpers";
import { mapState } from "vuex";
import EditProfile from "@/components/EditProfile.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import Notifications from "@/components/Notifications";
import EditPhoto from "@/components/EditPhoto";
import authService from "@/services/auth.service";

export default {
  components: {
    EditPhoto,
    Notifications,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BSidebar,
    // Navbar Components
    DarkToggler,
    EditProfile,
    ChangePassword
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      showEditProfileModal: false,
      showChangePasswordModal: false
    };
  },
  computed: {
    ...mapState("app", ["user"])
  },
  methods: {
    executeEditProfile(user) {
      this.showEditProfileModal = false;
      this.$store.dispatch("app/update_user", user);
    },
    executeChangePassword() {
      this.showChangePasswordModal = false;
    },
    async logout() {
      const res = await authService.logout();
      localStorage.clear();
      this.$router.push("login");
    },
    flew(words) {
      return firstLetterEachWord(words);
    }
  }
};
</script>

<style scoped>
.bg-secondary {
  background-color: #272727 !important;
}
</style>
