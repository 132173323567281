<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsUnread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
        style="color: white !important"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notificaciones
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notificationsUnread }}
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      :key="notifications.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media>
          <template #aside v-if="!notification.read">
            <b-avatar
              size="32"
              variant="danger"
            >
              <feather-icon icon="BellIcon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">
            {{ dateFormat(notification.created_at, 'DD MMMM YYYY, h:m') }}
          </small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer" v-if="notificationsUnread > 0">
      <b-overlay
        :show="loadingbtn"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="allNotificationsRead()"
        >
          Marcar todas como leidas
        </b-button>
      </b-overlay>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import UtilitiesService from '@/services/utilities.service'
import moment from 'moment'
moment.locale('es')
import { mapState } from 'vuex'
import Echo from 'laravel-echo';
import config from '@/config.js'

export default {
  components: {
    BOverlay,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingbtn: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      notifications: []
    }
  },
  computed: {
    notificationsUnread() {
      return this.notifications.filter(i => {
        return !i.read
      }).length
    }
  },
  methods: {
    async allNotificationsRead() {
      if (this.notifications.length) {
        this.loadingbtn = true
        const notif_ids = this.notifications.map(i => {
          return i.id
        })
        console.log(notif_ids)
        await UtilitiesService.allNotificationsRead({
          notif_ids: notif_ids
        })
        this.getNotifications()
        this.loadingbtn = false
      }
    },
    dateFormat(date, format = 'DD/MM/YYYY') {
      return moment(date).format(format)
    },
    async getNotifications() {
      const { data:res } = await UtilitiesService.getNotifications()
      this.notifications = res.data
      console.log(this.notifications)
    },
    handleNewNotification(data) {
      this.notifications.unshift(data)
      const audio = new Audio(require("../assets/sounds/notif.mp3"))
      audio.play()
    },
    echo(userId) {
      const echo = new Echo({
        broadcaster: 'socket.io',
        host: config.socketUrl,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        },
      });

      echo.channel(`user.${userId}`)
      .listen('.new-notification', (data) => {
        this.handleNewNotification(data.notification)
      })
    },
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user
		}),
    notificationsUnread() {
      return this.notifications.filter(i => {
        return !i.read
      }).length
    }
  },
  mounted() {
    setTimeout(() => {
      this.echo(this.user_state.id)
    }, 1000)
  },
  created() {
    this.getNotifications()
  }
}
</script>

<style>

</style>
