<template>
  <!-- form -->
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(updateProfile)">
      <!-- code -->
      <!-- <b-form-group label="Código" label-for="code">
        <ValidationProvider name="Código" rules="required" v-slot="{ errors }">
          <b-form-input id="code" v-model="client.code" name="code" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group> -->

      <!-- name -->
      <b-form-group label="Nombre" label-for="name">
        <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
          <b-form-input id="name" v-model="client.name" name="name" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- surname -->
      <b-form-group label="Apellido" label-for="surname">
        <ValidationProvider
          name="Apellido"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-input id="surname" v-model="client.surname" name="surname" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- email -->
      <b-form-group label="Email" label-for="email">
        <ValidationProvider
          name="Correo"
          rules="required|email"
          v-slot="{ errors }"
        >
          <b-form-input id="email" v-model="client.email" name="email" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- password -->
      <!-- <b-form-group label="Contraseña" label-for="password">
        <b-form-input id="password" v-model="client.password" name="password" />
      </b-form-group> -->
      <b-form-group label="Tipo de identificación" label-for="address">
        <ValidationProvider
          name="Tipo de identificación"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-select
            v-model="client.identification_type"
            :options="identificationTypes"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- identification_number -->
      <b-form-group
        label="Número de identificacón"
        label-for="identification_number"
      >
        <ValidationProvider
          name="Número de identificación"
          rules="required|integer"
          v-slot="{ errors }"
        >
          <b-form-input
            id="identification_number"
            v-model="client.identification_number"
            name="identification_number"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- address -->
      <b-form-group label="Dirección" label-for="address">
        <ValidationProvider
          name="Dirección"
          rules="required"
          v-slot="{ errors }"
        >
          <b-form-input id="address" v-model="client.address" name="address" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- phone -->
      <b-form-group label="Teléfono" label-for="phone">
        <ValidationProvider
          name="Teléfono"
          rules="required|integer"
          v-slot="{ errors }"
        >
          <b-form-input id="phone" v-model="client.phone" name="phone" />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!--
      <hr />
      <b-form-group
        label="Notificaciones a recibir"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio
          v-model="selectNotifications"
          :aria-describedby="ariaDescribedby"
          name="slect-notifications"
          value="basic"
          class="mb-1 mt-2"
          ><span id="tooltip-target-1"
            >Recibir solo notificaciones basicas del estado de mis
            paquetes</span
          ><b-tooltip target="tooltip-target-1" triggers="hover">
            Cuando llega a miami y cuando llega a Costa Rica
          </b-tooltip></b-form-radio
        >
        <b-form-radio
          v-model="selectNotifications"
          :aria-describedby="ariaDescribedby"
          name="slect-notifications"
          value="all"
          ><span id="tooltip-target-2"
            >Recibir todas las notificaciones del estado de mis paquetes</span
          ><b-tooltip target="tooltip-target-2" triggers="hover">
            Cuanto llega a miami, cuando esta en transito, cuando llega a Costa
            Rica
          </b-tooltip></b-form-radio
        >
      </b-form-group>
      <hr />-->
      <!-- submit button -->
      <div class="text-center">
        <b-button type="submit" variant="primary">
          <feather-icon icon="SaveIcon" size="16" /> Actualizar
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
//Composition api
import { ref, onMounted } from '@vue/composition-api'
//Services
import clientService from '@/services/client.service'
import authService from '@/services/auth.service'
//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
//Notification toast
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Bootsrap vue
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
  BFormRadio,
  BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormSelect,
    BFormRadio,
    BTooltip,
  },

  setup(props, { emit }) {
    const toast = useToast()

    const identificationTypes = ref([
      { value: '01', text: 'Cédula fisica' },
      { value: '02', text: 'Cédula juridica' },
      { value: '03', text: 'DIMEX' },
      { value: '04', text: 'NITE' },
    ])

    const baseClient = {
      name: '',
      surname: '',
      email: '',
      password: '',
      identification_type: identificationTypes[0],
      identification_number: '',
      address: '',
      phone: '',
      client_notifications: '',
    }

    const client = ref(JSON.parse(JSON.stringify(baseClient)))

    const selectNotifications = ref('all')

    const updateProfile = async () => {
      try {
        const { data: res } = await authService.updateProfile({
          ...client.value,
          client_notifications: selectNotifications.value,
        })

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
          return
        }

        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        emit('executeEditProfile', res.data)
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    //Get auth user
    const getUser = async () => {
      try {
        const { data: res } = await authService.me()

        const iType = identificationTypes.value.find(
          i => i.value == res.data.identification_type
        )

        client.value = res.data
        selectNotifications.value = res.data.client_notifications
      } catch (error) {
        console.log(error)
      }
    }

    //Mounted
    onMounted(() => {
      getUser()
    })

    return {
      updateProfile,
      client,
      identificationTypes,
      selectNotifications,
    }
  },
}
</script>

<style scoped></style>
