<template>
  <!-- form -->
  <ValidationObserver v-slot="{ handleSubmit }">
    <b-form @submit.prevent="handleSubmit(changePassword)">
      <!-- current password -->
      <b-form-group label="Contraseña actual" label-for="password">
        <ValidationProvider
          name="Contraseña actual"
          rules="required|min:6"
          v-slot="{ errors }"
        >
          <b-form-input
            id="password"
            v-model="client.password"
            name="password"
            type="password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- new password -->
      <b-form-group label="Nueva contraseña" label-for="new_password">
        <ValidationProvider
          name="Nueva contraseña"
          rules="required|min:6"
          v-slot="{ errors }"
          vid="new_password"
        >
          <b-form-input
            id="new_password"
            v-model="client.new_password"
            name="new_password"
            type="password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- retry new password -->
      <b-form-group
        label="Repite nueva contraseña"
        label-for="retry_new_password"
      >
        <ValidationProvider
          name="Repite nueva contraseña"
          v-slot="{ errors }"
          rules="required|min:6|confirmed:new_password"
        >
          <b-form-input
            type="password"
            id="retry_new_password"
            v-model="client.retry_new_password"
            name="retry_new_password"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
      </b-form-group>

      <!-- submit button -->
      <div class="text-center">
        <b-button type="submit" variant="primary">
          <feather-icon icon="SaveIcon" size="16" /> Actualizar
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
//Composition api
import { ref, onMounted } from '@vue/composition-api'
//Services
import authService from '@/services/auth.service'
//Validation forms
import { ValidationProvider, ValidationObserver } from 'vee-validate'
//Notification toast
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Bootsrap vue
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormSelect,
  },

  setup(props, { emit }) {
    const toast = useToast()

    const baseClient = {
      password: '',
      new_password: '',
      retry_new_password: '',
    }

    const client = ref(JSON.parse(JSON.stringify(baseClient)))

    const changePassword = async () => {
      try {
        const { data: res } = await authService.changePassword(client.value)

        if (!res.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'warning',
            },
          })
          return
        }

        client.value = JSON.parse(JSON.stringify(baseClient))

        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        emit('executeChangePassword', res.data)
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'CheckIcon',
            variant: 'warning',
          },
        })
      }
    }

    //Get auth user
    const getUser = async () => {
      try {
        const { data: res } = await authService.me()
        client.value = res.data
      } catch (error) {
        console.log(error)
      }
    }

    //Mounted
    onMounted(() => {
      //getUser()
    })

    return {
      changePassword,
      client,
    }
  },
}
</script>

<style scoped></style>
