<template>
  <div class="position-relative w-100 text-center mb-2" v-if="user">
    <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block"
    >
      <img v-if="user.photo" class="profile-photo" :src="user.url_photo" alt="">
      <b-avatar
        v-else
        size="70"
        variant="light-primary"
        :text="flew(user ? user.name : '')"
        class="badge-minimal"
        badge-variant="success"
      />
      <b-button
        variant="flat-success"
        class="btn-icon rounded-circle button-camera"
        @click="$refs.file.click()"
      >
        <feather-icon icon="CameraIcon" />
      </b-button>
    </b-overlay>
    <input type="file" ref="file" style="display: none" @change="savePhoto">
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { firstLetterEachWord } from '@/helpers'
import {
  BButton,
  BAvatar,
  BOverlay
} from 'bootstrap-vue'

import UserService from '@/services/user.service'
import Swal from 'sweetalert2'

export default {
  components: {
    BButton,
    BAvatar,
    BOverlay
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.app.user
		}),
  },
  methods: {
    async savePhoto(e) {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('photo', e.target.files[0])

        const { data:res } = await UserService.updateProfilePhoto(formData)

        if (res.success) {
          this.$store.dispatch('app/update_user', res.data)
        } else {
          Swal.fire({
            title: res.message,
            text: '',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }

        this.loading = false

      } catch (error) {
        this.loading = false
        Swal.fire({
          title: 'error interno',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    flew(words) {
      return firstLetterEachWord(words)
    },
  }
}
</script>

<style scoped>
  .profile-photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  .button-camera {
    position: absolute;
    right: -20px;
    bottom: -10px
  }
</style>
